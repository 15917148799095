.CardTextContainer {
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.MoreShadow {
  position: absolute;
  width: 100%;
  height: 100%;
  left: -7.5px;
  top: -7.5px;

  background-color: #3e4344ee;
  border-radius: 20px;
  border-width: 2px;
  border-style: solid;
  border-color: #3e4344ee;
}
.MoreButton {
  bottom: 0px;
  right: 0px;
  width: 100px;
  height: 50px;
  position: absolute;
}
.MoreButton:Hover .MoreShadow {
  border-radius: 25px;
  background-color: #4e5354ee;
  /* border-color: #4e5354ee; */
}
.MoreBack {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: #494f52;
  border-radius: 20px;
  border-width: 4.5px;
  border-style: solid;
  border-color: #3e4344ee;

  display: flex;
  justify-content: center;
  align-items: center;
}
.MoreButton:Hover .MoreBack {
  /* border-radius: 25px; */
  background-color: #595f62;
  /* border-color: #4e5354ee; */
}
.MoreText {
  font-family: 'Open Sans Bold', Monospace;
  font-size: 20px;
  text-decoration: none;
}