.CardContainer {
  width: 100%;
  height: 100%;

  /* make cards scroll snappy */
  scroll-snap-align: start;

  position: relative;

  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.Card {
  position: relative;
}

.LeftBackAnim {
  position: absolute;
  width: 100%;
  height: 100%;
  left: -20px;
  top: -20px;

  border-radius: 75px;
  background-color: #3e4344ee;
}
.RightBackAnim {
  position: absolute;
  width: 100%;
  height: 100%;

  border-radius: 75px;
  background-color: #494f52;
}

.CardContent {
  width: 100%;
  height: 100%;
  position: absolute;

  display: flex;
}

.LeftAnim {
  position: relative;
}
.RightAnim {
  position: relative;
}

.LeftDiv {
  position: absolute;

  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.RightDiv {
  position: absolute;

  /* display: flex;
  align-items: flex-start;
  flex-direction: column; */
}

/* TODO@ID should those stay here? actually they should not be here cause the component should not know about its content */
.LeftDiv img {
  object-fit: scale-down;
  max-width: 100%;
  max-height: 100%;
}
