.PopupMain {
  position: fixed;
  width: 100vw;
  height: 100vh;

  background-color: #000000AA;

  overflow-y: scroll;
  /* remove scrollbar */
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.PopupMain::-webkit-scrollbar {
  /* remove scrollbar */
  display: none;
}

.PopupX {
  position: absolute;
  right: 5%;
  top: 5%;

  font-size: 50px;
}
.PopupX:hover {
  color: #999999;
}

.PopupShadow {
  position: absolute;
  width: 100%;
  height: 100%;
  left: -20px;
  top: -20px;
  z-index: -1;

  border-radius: 75px;
  background-color: #3e4344;
}

.PopupBack {
  position: relative;
  margin: 10%;

  border-radius: 75px;
  background-color: #494f52;
}

.PopupText {
  padding: 35px;
}
