.Sidebar {
  position: fixed;
  width: 5%;
  max-width: 35px;
  height: 70%;
  top: 15%;
  right: 2%;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.SidebarItem {
  /* display: flex; */
  /* align-content: stretch; */
  margin-top: 5px;
  margin-bottom: 5px;
  /* height: 100%; */

  border-radius: 75px;
  background-color: #3e4344ee;
}

.SidebarItem:Hover {
  /* border-radius: 25px; */
  background-color: #4e5354ee;
  /* border-color: #4e5354ee; */
}