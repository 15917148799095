.CardDeck {
  width: 100vw;
  flex-grow: 1;

  /* make snappy scrolling possible */
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  overflow-x: hidden;

  /* remove scrollbar */
  -ms-overflow-style: none;
  scrollbar-width: none;

  /* background image */
  background: url(./background.svg) no-repeat center top fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.CardDeck::-webkit-scrollbar {
  /* remove scrollbar */
  display: none;
}
