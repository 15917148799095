@font-face {
  font-family: 'Open Sans Light';
  src: url(open-sans.light.ttf);
}
@font-face {
  font-family: 'Open Sans Light Italic';
  src: url(open-sans.light-italic.ttf);
}
@font-face {
  font-family: 'Open Sans Regular';
  src: url(open-sans.regular.ttf);
}
@font-face {
  font-family: 'Open Sans Regular Italic';
  src: url(open-sans.italic.ttf);
}
@font-face {
  font-family: 'Open Sans Semibold';
  src: url(open-sans.semibold.ttf);
}
@font-face {
  font-family: 'Open Sans Semibold Italic';
  src: url(open-sans.semibold-italic.ttf);
}
@font-face {
  font-family: 'Open Sans Bold';
  src: url(open-sans.bold.ttf);
}
@font-face {
  font-family: 'Open Sans Bold Italic';
  src: url(open-sans.bold-italic.ttf);
}

@font-face {
  font-family: 'Open Sans Extrabold';
  src: url(open-sans.extrabold.ttf);
}
@font-face {
  font-family: 'Open Sans Extrabold Italic';
  src: url(open-sans.extrabold-italic.ttf);
}

.App {
  width: 100vw;
  height: 100vh;
  display: flex;
  /* justify-content: flex-start; */
  flex-direction: column;

  background-color: #133945;

  font-family: 'Open Sans Semibold', Monospace;
  font-size: 20px;
  color: #cccccc;
}

.App a {
  color: #cccccc;
}
