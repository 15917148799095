.Menu {
  position: fixed;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
}

.MenuItem {
  /* margin-top: 17.5px;
  margin-left: 12.5px;
  margin-right: 5px; */
  margin-top: 7.5px;
  margin-left: 7.5px;
  margin-right: 7.5px;
  width: 150px;
  height: 60px;
  position: relative;
}
.MenuItemSel {
  margin-top: 7.5px;
  margin-left: 7.5px;
  margin-right: 7.5px;
  width: 150px;
  height: 60px;
  position: relative;
}
.MenuItemSel .MenuBack {
  background-color: #696f72;
}

.MenuShadow {
  position: absolute;
  width: 100%;
  height: 100%;
  left: -7.5px;
  top: -7.5px;

  background-color: #3e4344ee;
  border-radius: 20px;
  border-width: 2px;
  border-style: solid;
  border-color: #3e4344ee;
}
.MenuItem:Hover .MenuShadow {
  border-radius: 25px;
  background-color: #4e5354ee;
  /* border-color: #4e5354ee; */
}

.MenuBack {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: #494f52;
  border-radius: 20px;
  border-width: 4.5px;
  border-style: solid;
  border-color: #3e4344ee;

  display: flex;
  justify-content: center;
  align-items: center;
}
.MenuItem:Hover .MenuBack {
  /* border-radius: 25px; */
  background-color: #595f62;
  /* border-color: #4e5354ee; */
}

.MenuText {
  font-family: 'Open Sans Bold', Monospace;
  font-size: 20px;
  text-decoration: none;
}

.MenuIcon {
  max-width: 100%;
  max-height: 100%;
}
